<template>
  <div class="card">
    <div class="card-header h3  m-0 p-0">
      <ul class="nav nav-tabs nav-fill">
        <li v-if="hasCalendars" class="nav-item border-left border-right">
          <a @click="selected = 'calendar'"
             href="#"
             class="nav-link"
             :class="selected === 'calendar'?'active':''"
          >
            <cs-loading v-if="$wait.is('calendars.get')"></cs-loading>
            <cs-icon v-else icon="calendar"/>
            {{ titleCase(plural(program.calendar_label, calendars.length)) }}
          </a>

        </li>
        <li v-if="hasSessions" class="nav-item border-left border-right">
          <a @click="selected = 'session'"
             href="#"
             class="nav-link"
             :class="selected === 'session'?'active':''"
          >
            <cs-loading v-if="$wait.is('sessions.get')"></cs-loading>
            <cs-icon v-else icon="sessions"/>
            {{ titleCase(plural(program.course_session_label, sessions.length)) }}
          </a>
        </li>
        <li v-if="hasStoreSessions" class="nav-item border-left border-right">
          <a @click="selected = 'store_session'"
             href="#"
             class="nav-link"
             :class="selected === 'store_session'?'active':''"
          >
            <cs-loading v-if="$wait.is('sessions.get')"></cs-loading>
            <cs-icon v-else icon="store"/>
            {{ titleCase(plural(program.not_course_session_label, storeSessions.length)) }}
          </a>
        </li>
      </ul>
    </div>

    <div class="card-body">
      <transition name="fade" mode="out-in">
        <calendars v-if="selected === 'calendar'" :calendar-id.sync="selectedCalendarId"/>
        <sessions v-else-if="selected === 'session'" :session-id.sync="selectedSessionId" :course-slug.sync="selectedCourseSlug"/>
        <store-sessions v-else-if="selected === 'store_session'" :store-session-id.sync="selectedStoreSessionId"/>
        <p v-else class="lead">Pick above to view sign up choices</p>
      </transition>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { plural, titleCase } from '@/utilities/stringFormatters'
import { UPDATE_CALENDARS, UPDATE_SESSION, UPDATE_SESSIONS } from '@/constants/mutation-types'

export default {
  name: 'CalendarSessionPortal',
  components: {

    Calendars: () => import(/* webpackChunkName: "Calendars" */ '@/components/calendars_sessions/calendars/Calendars'),
    Sessions: () => import(/* webpackChunkName: "Sessions" */ '@/components/calendars_sessions/sessions/Sessions'),
    StoreSessions: () => import(/* webpackChunkName: "StoreSessions" */ '@/components/calendars_sessions/stores/StoreSessions.vue')
  },
  props: {
    sessionId: {
      type: [String, Number],
      required: false,
      default: null
    },
    calendarId: {
      type: String,
      required: false,
      default: null
    },
    courseSlug: {
      type: String,
      required: false,
      default: null
    },
    storeSessionId: {
      type: [String, Number],
      required: false,
      default: null
    },
    registrationId: {
      type: [String, Number],
      required: false,
      default: null
    },
    month: {
      type: [String, Number],
      required: false,
      default: null
    },
    selectTab: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      selected: '',
      selectedSessionId: this.sessionId,
      selectedCourseSlug: this.courseSlug,
      selectedStoreSessionId: this.storeSessionId,
      selectedCalendarId: this.calendarId
    }
  },
  created () {
    switch (this.selectTab) {
      case 'calendars':
        this.selected = 'calendar'
        break
      case 'sessions':
        this.selected = 'session'
        break
      case 'store_sessions':
        this.selected = 'store_session'
        break
      default:
        this.selected = this.selectTab
    }
  },
  watch: {
    selectedSessionId (sessionId) {
      if (this.selected === 'session') {
        this.setUrl('session', sessionId)
      }
    },
    selectedStoreSessionId (sessionId) {
      if (this.selected === 'store_session') {
        this.setUrl('store_session', sessionId)
      }
    },
    selectedCalendarId (calendarId) {
      if (this.selected === 'calendar') {
        this.setUrl('calendar', calendarId)
      }
    },
    selected (newSelection, oldSelection) {
      switch (newSelection) {
        case 'calendar':
          this.setUrl('calendar', this.selectedCalendarId)
          break
        case 'session':
          this.setUrl('session', this.selectedSessionId)
          break
        case 'store':
          this.setUrl('store_session', this.selectedStoreSessionId)
          break
      }
    }
  },
  mounted () {
    console.log('CalendarSessionPortal.mounted', this.registrationId)
    if (this.registrationId === null) {
      this.updateSessions()
      this.updateCalendars()
    }
  },
  computed: {
    ...mapGetters({
      year: 'year',
      program: 'program',
      unfilteredSessions: 'sessions',
      calendars: 'calendars',
      loggedIn: 'loggedIn',
      isManager: 'isProgramManager'
    }),
    countOfTabs () {
      let tabCount = 0
      if (this.hasCalendars) {
        tabCount = tabCount + 1
      }
      if (this.hasSessions) {
        tabCount = tabCount + 1
      }
      if (this.hasStoreSessions) {
        tabCount = tabCount + 1
      }
      return tabCount
    },
    name () {
      return this.data
    },
    hasCalendars () {
      if (!isEmpty(this.program) && !this.program.has_calendars) {
        return false
      }
      return !isEmpty(this.calendars) && this.calendars.length > 0
    },
    sessions () {
      return this.unfilteredSessions.filter(session => {
        return !session.not_courses
      })
    },
    hasSessions () {
      if (!isEmpty(this.program) && !this.program.has_sessions) {
        return false
      }
      return !isEmpty(this.sessions) && this.sessions.length > 0
    },
    storeSessions () {
      if (this.hasSessions) {
        return this.unfilteredSessions.filter(session => {
          return !!session.not_courses
        })
      }
      return []
    },
    hasStoreSessions () {
      return this.storeSessions.length > 0
    }
  },
  methods: {
    ...mapActions({
      updateSession: UPDATE_SESSION,
      updateSessions: UPDATE_SESSIONS,
      updateCalendars: UPDATE_CALENDARS
    }),
    plural,
    titleCase,
    throwError () {
      throw new Error('Sentry Error')
    },
    tabChanged (newIndex) {
      console.log('CalendarSessionPortal.tabChanged', newIndex, [1, 2].includes(newIndex))
      switch (newIndex) {
        case 0:
          this.setUrl('calendar', this.selectedCalendarId)
          break
        case 1:
          this.setUrl('session', this.selectedSessionId)
          break
        case 2:
          this.setUrl('store_session', this.selectedStoreSessionId)
          break
      }
    },
    setUrl (type, id) {
      const url = new URL(window.location.href)
      url.searchParams.delete('session_id')
      url.searchParams.delete('store_session_id')
      url.searchParams.delete('calendar_id')
      if (id !== null) {
        url.searchParams.set(type + '_id', id)
      }
      if (type === 'store_session') {
        url.searchParams.set('select', 'store')
      } else {
        url.searchParams.set('select', type + 's')
      }
      window.history.pushState({}, '', url)
    }
  }
}
</script>

<style>
.vts-tabs {
  overflow: hidden;
  border: 1px solid #565454;
  border-radius: 0.25rem;
}

.vts-tabs [role="tablist"] {
  display: flex;
}

.vts-tabs [role="tab"] {
  font-size: 1.4em;
  flex-grow: 1;
  border: 1px solid #555654;
  padding: 0.5rem;
}

.vts-tabs [role="tab"][aria-selected="true"] {
  border-bottom-color: #93e5a7;
  background: #d4edda;
  font-weight: bold;
}

.vts-tabs [role="tabpanel"] {

  background: white;
}
</style>
